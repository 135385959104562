export const numberWithCommas = (number: number) => {
    return number.toLocaleString('en-US', { 
		style: 'currency', 
		currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
	});
}

export const numberWithDecimalCommas = (number: number) => {
  return number.toLocaleString('en-US', { 
  style: 'currency', 
  currency: 'USD',
});
}

export const numberWithoutDollar = (number: number) => {
  return number.toLocaleString('en-US', { 
    minimumFractionDigits: 2, 
    maximumFractionDigits: 2 
  });
};

export const openWebAddress = (webAddress?: string | null) => {
  if (!webAddress || webAddress === 'Not Set') return;

  const url = webAddress.startsWith('https://') ? webAddress : `https://${webAddress}`;
  window.open(url, '_blank');
};

