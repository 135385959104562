import * as React from 'react'
import { Grid, Box, Fab } from '@mui/material'
import AccountDetails from 'pages/Company/AccountDetails/AccountDetails'
import Brightness7Icon from '@mui/icons-material/SettingsOutlined'
import LicenseList from './License/LicenseList'
import PaymentList from './Payments/PaymentList'
import CommentsList from './Comments/CommentsList'
import DocumentsList from './Documents/DocumentsList'
import VendorDetails from './VendorDetails/VendorDetails'
import AccountAgreementDetails from './AccountAggrementDetails/AccountAgreementDetails'
import PricingDetails from './PricingDetails/PricingDetails'
import CancellationDetails from './CancellationDetails/CancellationDetails'
// import TagsList from './Tags/TagsList'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { AccountDetailsModelReq } from 'reduxStore/account/accountDetailsById/accountDetailsModel'
import { fetchAccountDetailsById } from 'reduxStore/account/accountDetailsById/accountDetailsSlice'
import { fetchCompanyAccountDetailsById } from 'reduxStore/account/companyAccountDetailsById/accountDetailsSlice'
import { CompanyAccountDetailsModelReq } from 'reduxStore/account/companyAccountDetailsById/companyAccountDetailsModel'
import CompanyDetailsChart from './Chart/CompanyDetailsChart'
import { fetchAccountPaymentListById } from 'reduxStore/account/accountPaymentList/accountPaymentListByIdSlice'
import { fetchAccountUserDetailsById } from 'reduxStore/account/accountUserDetailsById/accountUserDetailsByIdSlice'
import { fetchTagListById } from 'reduxStore/tagList/tagListByIdSlice'
import CompanyDetailsDialog from './CompanyDetailsDialog'
import { fetchAccountAgreementDetailsById } from 'reduxStore/account/accountAgreement/getAccountAgreementDetails/accountCustomerBudgetByIdSlice'
import { fetchAlerts } from 'reduxStore/alert/Active/alertSlice'
import { fetchCompanyUserList } from 'reduxStore/customer/companyUser/companyUserList/companyUserListSlice'
import { fetchDepartment } from 'reduxStore/subcription/Department/DepartmentSlice'
import { fetchLicense } from 'reduxStore/account/license/licenseListByAccountID/licenseSlice'
import { LisenceListReq } from 'reduxStore/account/license/licenseListByAccountID/Model'
import { fetchAmazonDataByAccountId } from 'reduxStore/account/amazon/amazonDataByAccountIdSlice'
import { GcpListReq } from 'reduxStore/account/google/Model'
import { fetchGcpList } from 'reduxStore/account/google/gcpListSlice'
import { checkReturnValue, isSubsSectionNameAvailable } from 'components/Utility'
import PageTitle from 'components/PageTitle'
import { fetchStatus } from 'reduxStore/subcription/Status/StatusSlice'

export default function CompanyDetails() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()

  const addAccAgreementRes = useAppSelector((state) => state.addAccountAgreementDetailsRes)

  const [showDialog, setShowDialog] = React.useState(false)
  const updateDialogState = () => {
    setShowDialog(true)
  }

  if (localStorage.getItem('subsSectionName') === null) {
    localStorage.setItem(
      'subsSectionName',
      'Subscription,Licenses,Payments,Subscriotion Charts,Comments,Documents,Vendor,Account Agreement,Pricing,Cancelation,Tags',
    )
  }

  const updateCompanyDetailsDialogValFromChild = (passedval: boolean) => {
    setShowDialog(passedval)
  }
  // Get the userId param from the URL.
  let { id } = useParams()

  let accountDetailsByIdBody = {
    accountId: String(id),
    token: Token,
  } as AccountDetailsModelReq
  let companyAccountDetailsByIdBody = {
    accountId: String(id),
    token: Token,
  } as CompanyAccountDetailsModelReq

  let licenseListBody = {
    id: String(id),
    token: Token!,
  } as LisenceListReq

  let mainBody = {
    id: id,
    token: Token!,
  } as GcpListReq

  React.useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(fetchAlerts(Token!))
    dispatch(fetchAccountDetailsById(accountDetailsByIdBody))
    dispatch(fetchCompanyAccountDetailsById(companyAccountDetailsByIdBody))
    dispatch(fetchAccountPaymentListById(companyAccountDetailsByIdBody))
    dispatch(fetchAccountUserDetailsById(companyAccountDetailsByIdBody))
    dispatch(fetchTagListById(companyAccountDetailsByIdBody))
    dispatch(fetchAccountAgreementDetailsById(companyAccountDetailsByIdBody))
    dispatch(fetchCompanyUserList(Token!))
    dispatch(fetchDepartment(Token!))
     dispatch(fetchStatus(Token!))
    dispatch(fetchLicense(licenseListBody))
    dispatch(fetchAmazonDataByAccountId(companyAccountDetailsByIdBody))
    dispatch(fetchGcpList(mainBody))
    // dispatch(fetchOptimizationsDetailsByAccID(mainBody))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  React.useEffect(() => {
    if ('addAccAgreementRes' in addAccAgreementRes) {
      if (
        addAccAgreementRes.addAccAgreementRes !== undefined ||
        addAccAgreementRes.addAccAgreementRes !== null
      ) {
        if ('id' in addAccAgreementRes.addAccAgreementRes) {
          dispatch(fetchAccountAgreementDetailsById(companyAccountDetailsByIdBody))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token, addAccAgreementRes])

  const accountDetailsByid = useAppSelector((state) => state.accountDetails)

  return (
    <Box sx={{ flexGrow: 1 }} p={2}>
      <PageTitle
        title={
          checkReturnValue(accountDetailsByid?.accountDetails?.vendor?.name) !== 'Not Set'
            ? checkReturnValue(accountDetailsByid?.accountDetails?.vendor?.name)
            : 'Subscription Details'
        }
      />
      {showDialog ? (
        <CompanyDetailsDialog
          companyDetailsDialogEnable={showDialog}
          companyDetailsUpdateDialogState={updateCompanyDetailsDialogValFromChild}
        />
      ) : null}
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="stretch"
        spacing={2}
      >
        {isSubsSectionNameAvailable('Subscription') && (
          <Grid item xs={12} md={12} sm={4} lg={4} xl={4}>
            <AccountDetails />
          </Grid>
        )}

        {isSubsSectionNameAvailable('Subscriotion Charts') && (
          <Grid item xs={12} md={12} sm={8} lg={8} xl={8}>
            <CompanyDetailsChart />
          </Grid>
        )}

        {isSubsSectionNameAvailable('Licenses') && (
          <Grid item xs={12} sm={12} md={12} lg={7} xl={7}>
            <LicenseList />
          </Grid>
        )}
        {isSubsSectionNameAvailable('Payments') && (
          <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
            <PaymentList />
          </Grid>
        )}
        {isSubsSectionNameAvailable('Comments') && (
          <Grid item xs={12} md={4} sm={4} lg={4} xl={4}>
            <CommentsList />
          </Grid>
        )}
        {isSubsSectionNameAvailable('Documents') && (
          <Grid item xs={12} md={4} sm={4} lg={4} xl={4}>
            <DocumentsList />
          </Grid>
        )}
        {isSubsSectionNameAvailable('Vendor') && (
          <Grid item xs={12} md={4} sm={4} lg={4} xl={4}>
            <VendorDetails />
          </Grid>
        )}
        {isSubsSectionNameAvailable('Account Agreement') && (
          <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
            <AccountAgreementDetails />
          </Grid>
        )}
        {isSubsSectionNameAvailable('Pricing') && (
          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <PricingDetails />
          </Grid>
        )}
        {isSubsSectionNameAvailable('Cancelation') && (
          <Grid item xs={12} md={6} sm={6} lg={6} xl={6}>
            <CancellationDetails />
          </Grid>
        )}
        {/* {isSubsSectionNameAvailable('Tags') && (
          <Grid item xs={12} md={4} sm={4} lg={4} xl={4}>
            <TagsList />
          </Grid>
        )} */}
        <Fab
          sx={{
            background: '#30A289',
            display: 'flex',
            margin: '8px',
            position: 'fixed',
            right: '30px',
            bottom: '90px',
            '&:hover': { cursor: 'pointer', background: '#30A289' },
          }}
          aria-label="add"
          onClick={updateDialogState}
        >
          <Brightness7Icon />
        </Fab>
      </Grid>
    </Box>
  )
}
