import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { updateSubscription } from 'reduxStore/subcription/updateSubscription/updateSubscriptionSlice'
import {
  RawBody,
  updateSubscriptionBody,
} from 'reduxStore/subcription/updateSubscription/updateSubscriptionModel'
import { Result as CompanyUser } from 'reduxStore/customer/companyUser/companyUserList/Model'
import { AccountDetailsModelReq } from 'reduxStore/account/accountDetailsById/accountDetailsModel'
// import { fetchAccountDetailsById } from 'reduxStore/account/accountDetailsById/accountDetailsSlice'
import { useParams } from 'react-router-dom'
// import MicrosoftIntDialog from 'pages/MicrosoftUser/MicrosoftIntDialog'
import {
  numberWithCommas,
  numberWithDecimalCommas,
  numberWithoutDollar,
} from 'utils/numberWithCommas'
import AccDetailsOptimizations from 'pages/Company/AccountDetails/Optimizations/AccDetailsOptimizations'
import { styled } from '@mui/material/styles'
import { checkReturnValue, renderApplogieDate } from 'components/Utility'
import {
  EditSubscriptionBody,
  MainBody,
} from 'reduxStore/subcription/editSubscription/editSubscriptionModel'
import {
  editSubscriptionApi,
  editSubscriptionClearRes,
} from 'reduxStore/subcription/editSubscription/editSubscriptionSlice'
import { fetchAccountDetailsById } from 'reduxStore/account/accountDetailsById/accountDetailsSlice'
interface OwnerList {
  label: string
  id: number
}

const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#30a289', // Green underline
    height: '2px',
  },
})

// Custom styling for each Tab
const CustomTab = styled(Tab)({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: '15px',
  color: '#757575', // Gray for inactive
  '&.Mui-selected': {
    color: '#30a289', // Green for active tab
  },
})
export default function AccountDetails() {
  // Get the userId param from the URL.
  const editSubscriptionValue = useAppSelector((state) => state.editSubscriptionfetch)

  const [isEditing, setIsEditing] = React.useState(false)
  const [note, setNote] = React.useState('')

  // Correctly typing the ref
  const textFieldRef = React.useRef<HTMLDivElement | null>(null)

  const handleSave = () => {
    setIsEditing(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (textFieldRef.current && !textFieldRef.current.contains(event.target as Node)) {
      handleSave()
    }
  }

  React.useEffect(() => {
    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
      let updatedbody = {
        access_key: access_key,
        access_text: access_text,
        api_key: api_key,
        account_number: ac_number,
        department_id: selectedDepartment?.value,
        description: notes_value,
        monthly_projected_cost: monthly_cost,
        owner_id: selectedOwner?.id,
        renewal_date: dayjs(renewalDate).format('YYYY-MM-DD') + 'T12:00',
      } as RawBody

      if (aliasName !== null || aliasName !== '') {
        Object.assign(updatedbody, { vendor_alias: aliasName })
      }

      let mainbody = {
        id: String(accountDetailsByid?.accountDetails?.id!),
        updateBody: updatedbody,
        token: Token!,
      } as updateSubscriptionBody
      dispatch(updateSubscription(mainbody))
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    // eslint-disable-next-line
  }, [isEditing])
  const [selectedTab, setSelectedTab] = React.useState(0)

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const accountDetailsByid = useAppSelector((state) => state.accountDetails)
  const comapnyAccountDetailsById = useAppSelector((state) => state.companyAccountDetailsSlice)
  const subsDeptRes = useAppSelector((state) => state.subsdepartment)
  const subsStatusValue = useAppSelector((state) => state.subscriptionstatus)
  // const updateSubscriptionValue = useAppSelector((state) => state.updateSubscription)

  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleDialogClose = () => {
    setOpen(false)
  }

  const handleClose = (_event: {}, reason: string) => {
    if (reason === 'backdropClick') {
      return
    }
  }
  const [access_key, setAccessKey] = React.useState('')
  const [access_text, setAccessText] = React.useState('')
  const [api_key, setApiKey] = React.useState('')
  const [aliasName, setAliasName] = React.useState('')
  const [vendorName, setVendorName] = React.useState('')
  const [ac_number, setAccountNumber] = React.useState('')
  const [monthly_cost, setMonthlyCost] = React.useState('')
  const [notes_value, setNotesValue] = React.useState('')
  const [departmentList, setDepartmentList] = React.useState([] as any[])
  const [selectedDepartment, setSelectedDepartment] = React.useState<any | null>(null)
  const [statusList, setStatusList] = React.useState([] as any[])
  const [selectedStatus, setSelectedStatus] = React.useState<any | null>(null)
  const [renewalDate, setRenewalDate] = React.useState<Dayjs | null>(null)
  const [isActive, setIsActive] = React.useState(true)
  const handleSwitchChange = (event: any) => {
    setIsActive(event.target.checked)
  }
  const [subIgnoreVal, setSubIgnoreVal] = React.useState(false)
  const changeRenewalDate = (newValue: Dayjs | null) => {
    setRenewalDate(dayjs(newValue))
  }
  const [ownerList, setOwnerList] = React.useState([] as any[])
  const [selectedOwner, setSelectedOwner] = React.useState<OwnerList | null>(null)
  function handleLimitChange(value: OwnerList | null) {
    setSelectedOwner(value)
  }

  const [email, setEmail] = React.useState('')
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastname] = React.useState('')
  const [showDateErrorMsg, setShowDateErrorMsg] = React.useState(false)
  const [dateErrorMsg, setDateErrorMsg] = React.useState('')
  const [maxWidth] = React.useState<DialogProps['maxWidth']>(false as any)

  const sendMail = (email: string) => {
    window.location.href =
      // eslint-disable-next-line no-useless-concat
      'mailto:' + email + '?cc=' + '' + '&subject=' + vendorName + ' Question' + '&body=' + ''
  }
  const changeDepartment = (department: any) => {
    setSelectedDepartment(department)
  }
  const changeStatus = (status: any) => {
    setSelectedStatus(status)
  }
  const [showInactivePaymentDialog, setShowInActivePaymentDialog] = React.useState(false)

  React.useEffect(() => {
    setSubIgnoreVal(!isActive)
  }, [isActive])
  const updateSubscriptionApiCall = async () => {
    setShowDateErrorMsg(false)
    setDateErrorMsg('')
    let editApiCallBody = {} as EditSubscriptionBody
    editApiCallBody = {
      description: notes_value,
      monthly_projected_cost:
        monthly_cost !== ''
          ? monthly_cost.replace(',', '').replace('$', '')
          : monthly_cost.replace(',', '').replace('$', ''),
    } as EditSubscriptionBody
    if (renewalDate !== null) {
      Object.assign(editApiCallBody, {
        renewal_date: dayjs(renewalDate).format('YYYY-MM-DD') + 'T12:00',
      })
    }
    Object.assign(editApiCallBody, { ignore: subIgnoreVal })

    if (selectedOwner !== null) {
      Object.assign(editApiCallBody, { owner_id: selectedOwner?.id })
    }
    if (selectedDepartment !== null) {
      Object.assign(editApiCallBody, { department_id: selectedDepartment?.value })
    }
    if (selectedStatus !== null) {
      Object.assign(editApiCallBody, { status_id: selectedStatus?.value })
    }
    if (aliasName !== null || aliasName !== '') {
      Object.assign(editApiCallBody, { vendor_alias: aliasName })
    }
    // Edit Subscription Main Body
    let mainBody = {
      id: String(accountDetailsByid?.accountDetails?.id!),
      token: Token!,
      body: editApiCallBody,
    } as MainBody
    await dispatch(editSubscriptionApi(mainBody))
    await setOpen(false)
    await dispatch(editSubscriptionClearRes())
    let accountDetailsByIdBody = {
      accountId: String(id),
      token: Token,
    } as AccountDetailsModelReq
    await dispatch(fetchAccountDetailsById(accountDetailsByIdBody))
  }

  React.useEffect(() => {
    setNotesValue(note)
  }, [note])

  React.useEffect(() => {
    setAccessKey(accountDetailsByid.accountDetails.access_key)
    setAccessText(accountDetailsByid.accountDetails.access_text)
    setApiKey(accountDetailsByid.accountDetails.api_key)
    setAccountNumber(accountDetailsByid.accountDetails.account_number)
    setNotesValue(accountDetailsByid.accountDetails.description)
    setVendorName(accountDetailsByid.accountDetails?.vendor?.name!)
    setMonthlyCost(accountDetailsByid.accountDetails.monthly_projected_cost)
    setSubIgnoreVal(accountDetailsByid.accountDetails.ignore)
    setIsActive(!accountDetailsByid.accountDetails.ignore)
    setRenewalDate(
      checkReturnValue(accountDetailsByid?.accountDetails?.renewal_date) !== 'Not Set'
        ? dayjs(checkReturnValue(accountDetailsByid?.accountDetails?.renewal_date))
        : null,
    )
    setAliasName(
      checkReturnValue(accountDetailsByid?.accountDetails?.vendor_alias) !== 'Not Set'
        ? checkReturnValue(accountDetailsByid?.accountDetails?.vendor_alias)
        : '',
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountDetailsByid])
  React.useEffect(() => {
    setDepartmentList(
      checkReturnValue(subsDeptRes?.department?.results) !== 'Not Set'
        ? subsDeptRes?.department?.results.map((deptItem: any) => ({
            label: deptItem.name,
            value: deptItem.id,
          }))
        : ([] as any[]),
    )
  }, [subsDeptRes])
  React.useEffect(() => {
    setStatusList(
      checkReturnValue(subsStatusValue?.status?.results) !== 'Not Set'
        ? subsStatusValue?.status?.results.map((statusItem: any) => ({
            label: statusItem.name,
            value: statusItem.id,
          }))
        : ([] as any[]),
    )
  }, [subsStatusValue])
  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks, @typescript-eslint/no-unused-expressions
    setEmail(checkReturnValue(accountDetailsByid?.accountDetails?.owner?.user?.email))
    setFirstName(checkReturnValue(accountDetailsByid?.accountDetails?.owner?.user?.first_name))
    setLastname(checkReturnValue(accountDetailsByid?.accountDetails?.owner?.user?.last_name))
    setNote(accountDetailsByid?.accountDetails?.description)
    if (
      checkReturnValue(accountDetailsByid?.accountDetails?.owner?.user) !== 'Not Set' &&
      ownerList.length > 0
    ) {
      setSelectedOwner(
        ownerList.find(
          (item) => item.label === accountDetailsByid.accountDetails?.owner?.user?.username!,
        ),
      )
    }
    if (
      checkReturnValue(accountDetailsByid?.accountDetails?.department) !== 'Not Set' &&
      departmentList.length > 0
    ) {
      setSelectedDepartment(
        departmentList.find(
          (item) => item.label === accountDetailsByid?.accountDetails?.department?.name!,
        ),
      )
    }
    if (
      checkReturnValue(accountDetailsByid?.accountDetails?.status) !== 'Not Set' &&
      statusList.length > 0
    ) {
      setSelectedStatus(
        statusList.find((item) => item.label === accountDetailsByid?.accountDetails?.status?.name),
      )
    }
  }, [accountDetailsByid, departmentList, ownerList, statusList])

  const companyUserList = useAppSelector((state) => state.companyUserList)

  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    if (checkReturnValue(companyUserList?.companyUserList?.results) !== 'Not Set') {
      if (companyUserList.companyUserList.results.length > 0) {
        let ownerArr = companyUserList.companyUserList.results.map((item: CompanyUser) => {
          return {
            label: item.user?.username!,
            id: item.id,
          }
        })
        setOwnerList(ownerArr)
      } else setOwnerList([] as any[])
    } else setOwnerList([] as any[])
  }, [companyUserList])

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        {accountDetailsByid.loading && comapnyAccountDetailsById.loading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress color="inherit" size={30} />
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ width: '100%' }}>
            <CustomTabs value={selectedTab} onChange={handleChange} centered>
              <CustomTab label="SUBSCRIPTION" />
              <CustomTab label="VENDOR" />
            </CustomTabs>
            <Box mt={2}>
              {selectedTab === 0 && (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      RENEWAL DATE
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography variant="body1">
                      {checkReturnValue(
                        renderApplogieDate(accountDetailsByid?.accountDetails?.renewal_date!),
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      OWNER
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography
                      variant="body1"
                      onClick={() => sendMail(email !== '' ? email : 'not set')}
                      sx={{
                        color: '#30a289',

                        '&:hover': { color: '#134872', cursor: 'pointer' },
                      }}
                    >
                      {accountDetailsByid?.accountDetails?.owner !== null
                        ? firstName + ' ' + lastName
                        : 'Not Set'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      DEPARTMENT
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography
                      variant="body1"
                      sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                      {checkReturnValue(accountDetailsByid?.accountDetails?.department?.name)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      MONTHLY PROJECTED COST
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography variant="body1">
                      {accountDetailsByid?.accountDetails?.monthly_projected_cost !== undefined
                        ? `${
                            accountDetailsByid?.accountDetails?.monthly_projected_cost !== null
                              ? numberWithDecimalCommas(
                                  Number(
                                    accountDetailsByid?.accountDetails?.monthly_projected_cost,
                                  ),
                                )
                              : '$0.00'
                          }`
                        : 'not-set'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      STATUS
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography variant="body1">
                      {checkReturnValue(accountDetailsByid?.accountDetails?.status?.name)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      ACCOUNT #
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography variant="body1">
                      {checkReturnValue(accountDetailsByid?.accountDetails?.account_number)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      SPEND(CURRENT YEAR)
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography variant="body1">
                      {'companyAccountDetails' in comapnyAccountDetailsById
                        ? comapnyAccountDetailsById.companyAccountDetails.length > 0
                          ? 'cost' in comapnyAccountDetailsById.companyAccountDetails[0]
                            ? numberWithCommas(
                                Math.floor(
                                  Number(comapnyAccountDetailsById.companyAccountDetails[0].cost),
                                ),
                              )
                            : 'Not Set'
                          : 'Not Set'
                        : 'Not Set'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      CATEGORY
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography variant="body1">
                      {checkReturnValue(
                        accountDetailsByid?.accountDetails?.vendor?.vendor_class?.class_name,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      SPEND(LAST YEAR)
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography variant="body1">
                      {'companyAccountDetails' in comapnyAccountDetailsById
                        ? comapnyAccountDetailsById.companyAccountDetails.length > 0
                          ? 'monthly_avg_spend' in
                            comapnyAccountDetailsById.companyAccountDetails[0]
                            ? numberWithCommas(
                                Math.floor(
                                  Number(
                                    comapnyAccountDetailsById.companyAccountDetails[0]
                                      .monthly_avg_spend,
                                  ),
                                ),
                              )
                            : 'Not Set'
                          : 'Not Set'
                        : 'Not Set'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      SPEND(MONTHLY AVG.)
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography variant="body1">
                      {'companyAccountDetails' in comapnyAccountDetailsById
                        ? comapnyAccountDetailsById.companyAccountDetails.length > 0
                          ? 'monthly_avg_spend' in
                            comapnyAccountDetailsById.companyAccountDetails[0]
                            ? numberWithCommas(
                                Math.floor(
                                  Number(
                                    comapnyAccountDetailsById.companyAccountDetails[0]
                                      .monthly_avg_spend,
                                  ),
                                ),
                              )
                            : 'Not Set'
                          : 'Not Set'
                        : 'Not Set'}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography variant="subtitle2" color="textSecondary">
                      LAST PAYMENT DATE
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} textAlign="right">
                    <Typography variant="body1">
                      {checkReturnValue(
                        renderApplogieDate(accountDetailsByid?.accountDetails?.renewal_date!),
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      id="standard-basic"
                      label="NOTES"
                      variant="standard"
                      multiline
                      rows={3}
                      value={notes_value}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              )}

              {selectedTab === 1 && (
                <Stack
                  direction="column"
                  spacing={2}
                  sx={{
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                  }}
                >
                  <Alert severity="warning">This section is currently under development</Alert>
                </Stack>
              )}
            </Box>
            {selectedTab === 0 && (
              <Box textAlign="right" mt={2}>
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ cursor: 'pointer', fontWeight: 'bold' }}
                  onClick={handleClickOpen}
                >
                  EDIT
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </CardContent>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction={'row'}>
              <Typography variant="h6" fontWeight="bold">
                {vendorName}
              </Typography>
            </Stack>
            <Stack direction={'row'}>
              <Tooltip
                title={
                  subIgnoreVal
                    ? "Active subscriptions will no longer appear on the 'Ignored Subscriptions' list"
                    : "Inactive subscriptions will be moved to the 'Ignored Subscriptions' list"
                }
              >
                <FormControlLabel
                  control={<Switch checked={isActive} onChange={handleSwitchChange} />}
                  label={subIgnoreVal ? 'Inactive' : 'Active'}
                />
              </Tooltip>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            pb={2}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                id="subscriptionAliasName-id"
                label="Alias Name"
                variant="standard"
                fullWidth
                value={aliasName}
                onChange={(e) => {
                  setAliasName(e.target.value)
                }}
                autoComplete='off'
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Renewal Date"
                  value={renewalDate}
                  onChange={(newValue) => {
                    changeRenewalDate(newValue)
                  }}
                  slotProps={{ textField: { variant: 'standard' } }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
              {showDateErrorMsg ? (
                <Typography variant="body1" sx={{ color: '#f44336' }}>
                  {dateErrorMsg}
                </Typography>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                value={selectedOwner}
                options={ownerList}
                onChange={(_event, value: any) => handleLimitChange(value!)}
                getOptionLabel={(option) => `${option.label}`}
                isOptionEqualToValue={(option: any, value) => option.id === value.id}
                id="disable-close-on-select"
                renderInput={(params) => (
                  <TextField {...params} label="Owner" variant="standard" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                id="dept-states"
                value={selectedDepartment}
                options={departmentList}
                onChange={(_event: any, newValue: any) => {
                  changeDepartment(newValue)
                }}
                getOptionLabel={(option: any) => option.label}
                isOptionEqualToValue={(option: any, value) => option.value === value.value}
                renderInput={(params) => (
                  <TextField {...params} label="Department Name" variant="standard" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                id="standard-basic"
                label="Monthly Projected Cost"
                variant="standard"
                type="number"
                defaultValue={
                  monthly_cost !== undefined
                    ? `${
                        monthly_cost !== null ? numberWithoutDollar(Number(monthly_cost)) : '0.00'
                      }`
                    : 'not-set'
                }
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                onChange={(e) => {
                  setAccountNumber(e.target.value)
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Autocomplete
                id="status-states"
                value={selectedStatus}
                options={statusList}
                onChange={(_event: any, newValue: any) => {
                  changeStatus(newValue)
                }}
                getOptionLabel={(option: any) => option.label}
                isOptionEqualToValue={(option: any, value) => option.value === value.value}
                renderInput={(params) => (
                  <TextField {...params} label="Status" variant="standard" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                id="standard-basic"
                label="Notes"
                variant="standard"
                multiline
                rows={3}
                value={notes_value}
                onChange={(e) => {
                  setNotesValue(e.target.value)
                }}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={handleDialogClose}
            disabled={editSubscriptionValue.loading}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography>Cancel</Typography>
              </Grid>
            </Grid>
          </Button>
          <Button variant="contained" onClick={updateSubscriptionApiCall}>
            {editSubscriptionValue.loading === true ? (
              <Stack direction="row" spacing={1}>
                <Typography>Save</Typography>
                <Box pl={1}>
                  <CircularProgress color="inherit" size={18} />
                </Box>
              </Stack>
            ) : (
              'Save'
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showInactivePaymentDialog}
        maxWidth={maxWidth}
        onClose={() => setShowInActivePaymentDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <AccDetailsOptimizations />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setShowInActivePaymentDialog(false)}
            style={{ backgroundColor: '#dcdcdc', color: '#000' }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}
