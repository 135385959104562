import * as React from 'react'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ConnectData from 'pages/Subscription/Active/ConnectData/ConnectData'
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import { fetchSubscription } from 'reduxStore/subcription/Active/subscriptionSlice'
import RefreshIcon from '@mui/icons-material/Refresh'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import { fetchStatus } from 'reduxStore/subcription/Status/StatusSlice'
import { fetchDepartment } from 'reduxStore/subcription/Department/DepartmentSlice'
import { fetchPaymentVendorList } from 'reduxStore/subcription/PaymentVendorList/paymentVendorListSlice'
import { fetchCompanyUserList } from 'reduxStore/customer/companyUser/companyUserList/companyUserListSlice'
import { TagSourceReq } from 'reduxStore/subcription/TagList/Model'
import { fetchTaglist } from 'reduxStore/subcription/TagList/tagSlice'
import SearchIcon from '@mui/icons-material/Search'
import {
  DataGridPremium,
  // GridRowParams,
  DataGridPremiumProps,
  GridFilterModel,
  GridFilterItem,
  GridRowSelectionModel,
  GridColumnVisibilityModel,
  GridLogicOperator,
  GridPinnedColumnFields,
  GRID_CHECKBOX_SELECTION_COL_DEF,
} from '@mui/x-data-grid-premium'
// import EditSubscription from '../../../components/Subscripiton/EditSubscription'
import { AllActiveSubscriptionList } from '../../../components/Subscripiton/AllActiveSubscriptionList'
import { SubscriptionListByTag } from '../../../components/Subscripiton/SubscriptionListByTag'
import { SubscriptionTableColumn } from '../../../components/Subscripiton/SubscriptionTableColumn'
import { updateSubscriptionList } from 'reduxStore/selectSubscriptionForSetDept/selectSubscriptionForSetDeptSlice'
import { editSubscriptionClearRes } from 'reduxStore/subcription/editSubscription/editSubscriptionSlice'
import SubscriptionAlert from 'components/Subscripiton/SubscriptionAlert'
import { optimizationDetailsByAccIdClearRes } from 'reduxStore/optimizations/optimizationsDetailsbyAccId/optimizationSlice'
import { ApplogieDGToolbar } from 'components/Subscripiton/DataGridTableHeader'
import { fetchUserDisplaySetting } from 'reduxStore/subcription/FetchUserDisplaySetting/fetchUserDisplaySettingSlice'
import { updateUserDisplaySetting } from 'reduxStore/subcription/CreateOrUpdateUserDisplaySetting/createOrUpdateUserDisplaySettingSlice'
import {
  UpdateUserDisplaySettingBody,
  User,
} from 'reduxStore/subcription/CreateOrUpdateUserDisplaySetting/Model'
import debounce from 'lodash/debounce'
import EditSubscriptionDialog from '../EditSubscriptionDialog'
import { ModifiedSubscriptionList } from '../EditSubscriptionDialog/Model'
export default function ActiveSubscription() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  let navigate = useNavigate()
  const subsValue = useAppSelector((state) => state.subscription)
  const paymentVendorValue = useAppSelector((state) => state.paymentVendorList)
  const editSubscriptionValue = useAppSelector((state) => state.editSubscriptionfetch)
  const tagListValue = useAppSelector((state) => state.tagList)
  const searchTagListValue = useAppSelector((state) => state.searchTagList)
  const individualSubscriptionAlertRes = useAppSelector(
    (state) => state.optimizationsDetailsbyAccountID,
  )
  // Individual subscriptions Alert
  const [showAlertDialog, setShowAlertDialog] = React.useState(false)
  const updateAlertDialogValFromChild = (passedVal: boolean) => {
    setShowAlertDialog(passedVal)
  }
  useEffect(() => {
    setShowAlertDialog(
      individualSubscriptionAlertRes.optimizations?.results !== undefined ? true : false,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualSubscriptionAlertRes])

  // Initiate the following functions only when the page renders
  useEffect(() => {
    dispatch(fetchSubscription(Token!))
    dispatch(fetchStatus(Token!))
    dispatch(fetchDepartment(Token!))
    dispatch(fetchUserDisplaySetting(Token!))

    let reqBody = {
      resultCount: '5',
      token: Token!,
    }
    dispatch(fetchPaymentVendorList(reqBody))
    dispatch(fetchCompanyUserList(Token!))
    dispatch(fetchCompanyUserList(Token!))
    let tagBody = {
      token: Token!,
    } as TagSourceReq
    dispatch(fetchTaglist(tagBody))
    dispatch(optimizationDetailsByAccIdClearRes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  const [searchKey, setSearchKey] = React.useState([] as any[])

  const [showDialog, setShowDialog] = React.useState(false)
  const updateDialogState = () => {
    setShowDialog(true)
  }
  const updateSuccessMsgChild = (passedVal: boolean) => {
    setShowDialog(passedVal)
  }

  let [searchParams, setSearchParams] = useSearchParams()
  const [subscriptionList, setSubscriptionList] = React.useState([] as any[])
  useEffect(() => {
    if (
      searchParams.get('searchbyname') !== null ||
      searchParams.get('searchbyowner') !== null ||
      searchParams.get('searchbydepartment') !== null ||
      searchParams.get('quick-filter') !== null
    ) {
      let filterItem = [] as GridFilterItem[]
      let quickFilterItem = [] as string[]
      // get name value from query param & set it to filter
      if (searchParams.get('searchbyname') !== null) {
        if (searchParams.get('searchbyname') !== '') {
          const nameObj = {
            field: 'name',
            operator: 'contains',
            value: searchParams.get('searchbyname'),
          } as any
          filterItem = [...filterItem, nameObj]
          setFilterModel({ items: filterItem, quickFilterValues: quickFilterItem })
        }
      }
      // get owner value from query param & set it to filter
      if (searchParams.get('searchbyowner') !== null) {
        if (searchParams.get('searchbyowner') !== '') {
          const departmentObj = {
            field: 'userEmail',
            operator: 'contains',
            value: searchParams.get('searchbyowner'),
          } as any
          filterItem = [...filterItem, departmentObj]
          setFilterModel({ items: filterItem, quickFilterValues: quickFilterItem })
        }
      }
      // get name department from query param & set it to filter
      if (searchParams.get('searchbydepartment') !== null) {
        if (searchParams.get('searchbydepartment') !== '') {
          const departmentObj = {
            field: 'department',
            operator: 'contains',
            value: searchParams.get('searchbydepartment'),
          } as any
          filterItem = [...filterItem, departmentObj]
          setFilterModel({ items: filterItem, quickFilterValues: quickFilterItem })
        }
      }
      // get quick filter value from query param & set it to quickFilter
      if (searchParams.get('quick-filter') !== null) {
        if (searchParams.get('quick-filter') !== '') {
          const quickFilterValue = [searchParams.get('quick-filter')] as any[]
          setFilterModel({ items: filterItem, quickFilterValues: quickFilterValue })
        }
      } else {
        setFilterModel({ items: filterItem })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, subscriptionList, searchKey])

  const handleUpdateSetSearchName = (name: string) => {
    if (name !== undefined) {
      if (name !== '') {
        searchParams.set('searchbyname', name)
        setSearchParams(searchParams)
      }
    }
  }
  const handleUpdateSearchOwner = (owner: string) => {
    if (owner !== undefined) {
      if (owner !== '') {
        searchParams.set('searchbyowner', owner)
        setSearchParams(searchParams)
      }
    }
  }
  const handleUpdateSearchDept = (department: string) => {
    if (department !== undefined) {
      if (department !== '') {
        searchParams.set('searchbydepartment', department)
        setSearchParams(searchParams)
      }
    }
  }

  // Modify dataset for subscription table
  let allSubscriptionList = AllActiveSubscriptionList()
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    allSubscriptionList.length > 0
      ? setSubscriptionList(allSubscriptionList)
      : setSubscriptionList([] as any[])
  }, [allSubscriptionList])

  useEffect(() => {
    if (tagListValue !== undefined) {
      let tagname = ''
      let searchKey: any[] = []
      if (tagListValue.tagSource !== undefined) {
        if (tagListValue.tagSource.tags !== undefined) {
          for (var k = 0; k < tagListValue.tagSource.tags.length; k++) {
            tagname = tagListValue.tagSource.tags[k]
            searchKey.push({ id: k + 1, name: tagname })
          }
          setSearchKey(searchKey) //as any
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagListValue])

  // reset table filter value after successfully edit subscription
  useEffect(() => {
    if (editSubscriptionValue?.succssMessage !== '') {
      if (filterModel?.quickFilterValues !== undefined) {
        if (filterModel?.quickFilterValues!.length > 0) {
          setFilterModel({
            items: [],
            quickFilterValues: [],
          })
          searchParams.delete('quick-filter')
          setSearchParams(searchParams)
          dispatch(editSubscriptionClearRes())
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSubscriptionValue])

  // Set subscription table data from Tag list search
  let subscripitonListByTag = SubscriptionListByTag()

  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    subscripitonListByTag.length > 0
      ? setSubscriptionList(subscripitonListByTag)
      : setSubscriptionList([] as any[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscripitonListByTag])

  // function DetailPanelContent({ row }: { row: any }) {
  //   return <EditSubscription item={row} />
  // }

  // const getDetailPanelContent = React.useCallback(
  //   ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //   [],
  // )

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, [])

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
    quickFilterExcludeHiddenColumns: false,
    quickFilterLogicOperator: GridLogicOperator.Or,
    logicOperator: GridLogicOperator.Or,
  })

  useEffect(() => {
    if (filterModel.items.length > 0) {
      // Set Name, Owner, Department & QuickFilter value in query param when user use column filter & quick filter actions
      if (filterModel.items.find((e) => e.field === 'name')) {
        if (
          filterModel.items.find((e) => e.field === 'name')?.value !== undefined ||
          filterModel.items.find((e) => e.field === 'name')?.value !== ''
        ) {
          handleUpdateSetSearchName(filterModel.items.find((e) => e.field === 'name')?.value)
        } else {
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      }
      if (filterModel.items.find((e) => e.field === 'userEmail')) {
        if (
          filterModel.items.find((e) => e.field === 'userEmail')?.value !== undefined ||
          filterModel.items.find((e) => e.field === 'userEmail')?.value !== ''
        ) {
          handleUpdateSearchOwner(filterModel.items.find((e) => e.field === 'userEmail')?.value!)
        } else {
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      }
      if (filterModel.items.find((e) => e.field === 'department')) {
        if (
          filterModel.items.find((e) => e.field === 'department')?.value !== undefined ||
          filterModel.items.find((e) => e.field === 'department')?.value !== ''
        ) {
          handleUpdateSearchDept(filterModel.items.find((e) => e.field === 'department')?.value!)
        } else {
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      }

      if (
        searchParams.get('searchbyname')! !== null &&
        filterModel.items.find((e) => e.field === 'name') === undefined
      ) {
        searchParams.delete('searchbyname')
        setSearchParams(searchParams)
      } else if (
        searchParams.get('searchbyowner')! !== null &&
        (filterModel.items.find((e) => e.field === 'userEmail') === undefined ||
          filterModel.items.find((e) => e.field === 'userEmail')?.value === '')
      ) {
        searchParams.delete('searchbyowner')
        setSearchParams(searchParams)
      } else if (
        searchParams.get('searchbydepartment')! !== null &&
        (filterModel.items.find((e) => e.field === 'department') === undefined ||
          filterModel.items.find((e) => e.field === 'department')?.value === '')
      ) {
        searchParams.delete('searchbydepartment')
        setSearchParams(searchParams)
      }
      if (filterModel?.quickFilterValues !== undefined) {
        if (filterModel?.quickFilterValues!.length > 0) {
          searchParams.set('quick-filter', filterModel?.quickFilterValues![0])
          setSearchParams(searchParams)
        } else {
          searchParams.delete('quick-filter')
          setSearchParams(searchParams)
        }
      } else {
        searchParams.delete('quick-filter')
        setSearchParams(searchParams)
      }
    } else {
      searchParams.delete('searchbyname')
      searchParams.delete('searchbyowner')
      searchParams.delete('searchbydepartment')
      setSearchParams(searchParams)
      // Set QuickFilter value in query param when user does not use column filter
      if (filterModel?.quickFilterValues !== undefined) {
        if (filterModel?.quickFilterValues.length > 0) {
          searchParams.set('quick-filter', filterModel?.quickFilterValues[0])
          setSearchParams(searchParams)
        } else {
          searchParams.delete('quick-filter')
          setSearchParams(searchParams)
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      } else {
        searchParams.delete('quick-filter')
        setSearchParams(searchParams)
        navigate('.', { replace: true, state: { key: Date.now() } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterModel])

  // table checkbox selection
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([])
  // find selected subscription details on table selection & save that data in store for add department into that selected items
  useEffect(() => {
    if (selectionModel !== undefined) {
      let selectedSubscriptions = [] as any[]
      selectionModel.forEach((item: any) => {
        if (!selectedSubscriptions.includes(item)) {
          selectedSubscriptions.push(subscriptionList.find((o) => o.id === item))
        }
      })
      dispatch(updateSubscriptionList(selectedSubscriptions))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionModel, subscriptionList])

  // clear subscription table selected value when subscription table value gets changed
  useEffect(() => {
    if (subsValue?.subscriptions?.results !== undefined) {
      setSelectionModel([])
    }
  }, [subsValue])

  // Table Column visibility

  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>()
  let tableColumn = SubscriptionTableColumn()

  // For Grid Display Settings List get  API
  const getSubscriptionTableHeaderSetting = useAppSelector((state) => state.getUserDisplaySettings)
  const [modifiedDisplaySettings, setModifiedDisplaySettings] = React.useState({} as any)
  useEffect(() => {
    if (
      getSubscriptionTableHeaderSetting?.userDisplaySetting?.results &&
      Array.isArray(getSubscriptionTableHeaderSetting.userDisplaySetting.results) &&
      getSubscriptionTableHeaderSetting.userDisplaySetting.results.length > 0
    ) {
      const userDisplaySettingsObj =
        getSubscriptionTableHeaderSetting.userDisplaySetting.results.map((element: any) => ({
          id: element.id,
          subscriptionListTextColumnJustification: element?.subscriptionListTextColumnJustification,
          subscriptionListDateColumnJustification: element?.subscriptionListDateColumnJustification,
          subscriptionListNumberColumnJustification:
            element?.subscriptionListNumberColumnJustification,
          subscriptionListCurrencyColumnJustification:
            element?.subscriptionListCurrencyColumnJustification,
          subscriptionListStatusColumnJustification:
            element?.subscriptionListStatusColumnJustification,
          subscriptionListConfig: element?.subscriptionListConfig,
        }))
      setModifiedDisplaySettings(userDisplaySettingsObj[0])
    }
  }, [getSubscriptionTableHeaderSetting])

  const displaysubscriptionListSetting = modifiedDisplaySettings.subscriptionListConfig as string
  // for column ordering .....
  if (displaysubscriptionListSetting !== undefined) {
    const modifiedSubSetting = JSON.parse(displaysubscriptionListSetting)
    const modifiedColumnOrder = modifiedSubSetting.columns.orderedFields
    const modifiedColumnWidth = modifiedSubSetting.columns.lookup
    // console.log("modifiedColumnWidth=====", modifiedColumnWidth);
    let keepSubscriptionListOrder: any[] = tableColumn.sort((a, b) => {
      return (
        modifiedColumnOrder.findIndex((p: string | undefined) => p === a.field) -
        modifiedColumnOrder.findIndex((p: string | undefined) => p === b.field)
      )
    })
    // for column width large and small
    let updatedColumns = keepSubscriptionListOrder.map((column) => {
      const columnConfig = modifiedColumnWidth[column.field]
      return {
        ...column,
        width: columnConfig ? columnConfig.width : column.width,
        align: columnConfig ? columnConfig.align : 'left',
      }
    })
    tableColumn = updatedColumns
  }

  //for show-hide column......
  useEffect(() => {
    if (displaysubscriptionListSetting !== undefined) {
      const modifiedSubSetting = JSON.parse(displaysubscriptionListSetting)
      const modifiedColumnOrderVisibilityModel = modifiedSubSetting.columns.columnVisibilityModel
      setColumnVisibilityModel(modifiedColumnOrderVisibilityModel)
    }
  }, [displaysubscriptionListSetting])
  //table Sorting store in server..............

  var userDetails = JSON.parse(localStorage.getItem('user')!) as User
  const initialState = React.useMemo(() => {
    const stateJSON = localStorage.getItem('state')
    if (!stateJSON) {
      return {}
    }
    try {
      return JSON.parse(stateJSON)
    } catch {
      return {}
    }
  }, [])

  // Save state to local storage
  const saveStateToLocalStorage = debounce((state: any) => {
    localStorage.setItem('state', JSON.stringify(state))
    let logedInUser = {
      type: 'User',
      id: userDetails.id,
    } as User
    let updateUserDisplaySettingsBody = {
      subscriptionListTextColumnJustification: 'left',
      subscriptionListDateColumnJustification: 'left',
      subscriptionListNumberColumnJustification: 'right',
      subscriptionListCurrencyColumnJustification: 'right',
      subscriptionListStatusColumnJustification: 'center',
      subscriptionListConfig: JSON.stringify(state),
      user: logedInUser,
      token: Token!,
    } as unknown as UpdateUserDisplaySettingBody

    // Dispatch the create user settings API action
    dispatch(updateUserDisplaySetting(updateUserDisplaySettingsBody))
  })

  const [selectedItem, setSelectedItem] = React.useState({} as ModifiedSubscriptionList)
  const updateSelectedItemValFromChild = (passedVal: ModifiedSubscriptionList) => {
    setSelectedItem(passedVal)
  }
  const [showUpdateSubscriptionDialog, setShowUpdateSubscriptionDialog] = React.useState(false)
  const updateEditSubscriptionDialogValFromChild = (passedVal: boolean) => {
    if (passedVal) {
    }
    setShowUpdateSubscriptionDialog(passedVal)
  }
  // const updatePaymentRes = useAppSelector((state) => state.updatePaymentRes)

  useEffect(() => {
    setSelectedItem(editSubscriptionValue?.selectedSubscriptionTableItem)
    setShowUpdateSubscriptionDialog(editSubscriptionValue?.enableEditDialog)
  }, [editSubscriptionValue])

  const [pinnedColumns, setPinnedColumns] = React.useState<GridPinnedColumnFields>({
    left: [GRID_CHECKBOX_SELECTION_COL_DEF.field],
    right: ['id', 'vendorWebAddress'],
  })

  const handlePinnedColumnsChange = React.useCallback(
    (updatedPinnedColumns: GridPinnedColumnFields) => {
      setPinnedColumns(updatedPinnedColumns)
    },
    [],
  )
  return (
    <Box>
      <Card>
        <CardHeader
          title={
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{ flexWrap: 'wrap' }}
            >
              <Grid
                item
                xs={12}
                sm="auto"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  gap: 1,
                }}
              >
                <CloudQueueIcon sx={{ fontSize: { xs: '1.2rem', sm: '1.5rem' } }} />
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: { xs: '1rem', sm: '1.25rem' },
                  }}
                >
                  Subscriptions
                </Typography>
                <Chip
                  sx={{
                    width: '50px',
                    height: '24px',
                    fontSize: { xs: '0.75rem', sm: '0.875rem' },
                  }}
                  label={subscriptionList.length ? String(subscriptionList.length) : '0'}
                />
                <RefreshIcon
                  sx={{
                    cursor: 'pointer',
                    fontSize: { xs: '1.25rem', sm: '1.5rem' },
                  }}
                  onClick={() => dispatch(fetchSubscription(Token!))}
                />
              </Grid>
              <Grid item xs={12} sm display="flex" justifyContent="center">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {!subsValue?.loading && !searchTagListValue?.loading && (
                    <SearchIcon sx={{ color: 'gray', marginRight: '8px' }} />
                  )}
                  <Box id="filter-panel" />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<InsertLinkIcon />}
                  onClick={() => updateDialogState()}
                  fullWidth
                >
                  Connect Data
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {subsValue.loading || searchTagListValue.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" size={20} thickness={4} />
            </Grid>
          ) : (
            <Box sx={{ height: 'calc(100vh - 265px)' }}>
              <DataGridPremium
                columns={tableColumn}
                rows={subscriptionList}
                onStateChange={(state) => {
                  saveStateToLocalStorage(state)
                }}
                initialState={initialState}
                getDetailPanelHeight={getDetailPanelHeight}
                // getDetailPanelContent={
                //   selectionModel.length <= 0 ? getDetailPanelContent : undefined
                // }
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                slots={{
                  toolbar: ApplogieDGToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                hideFooterRowCount
                hideFooterSelectedRowCount
                checkboxSelection
                onRowSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel)
                }}
                rowSelectionModel={selectionModel}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                pinnedColumns={pinnedColumns}
                onPinnedColumnsChange={handlePinnedColumnsChange}
                disableRowGrouping
                disableAggregation
                disableRowSelectionOnClick
                loading={individualSubscriptionAlertRes.loading || subsValue.loading}
                localeText={{ noRowsLabel: 'No Subscriptions are available' }}
                sx={{
                  '@media print': {
                    '.MuiDataGrid-main': {
                      width: 'fit-content',
                      fontSize: '20px',
                      height: 'fit-content',
                      overflow: 'visible',
                    },
                    marginBottom: 100,
                  },
                  '& .MuiDataGrid-footerContainer': {
                    height: '0px',
                    minHeight: '0px',
                  },
                  '& .MuiDataGrid-columnSeparator': {
                    visibility: 'visible',
                  },
                  '& .MuiDataGrid-columnHeaderTitle': {
                    whiteSpace: 'normal',
                    lineHeight: '1.2',
                    wordWrap: 'break-word',
                  },
                }}
              />
            </Box>
          )}
        </CardContent>
      </Card>
      {showDialog ? (
        <ConnectData
          dialogEnable={showDialog}
          updatedSuccessMsgState={updateSuccessMsgChild}
          paymentVendorValue={paymentVendorValue}
        />
      ) : null}

      {showAlertDialog ? (
        <SubscriptionAlert
          alertDialogEnable={showAlertDialog}
          alertDialogUpdateState={updateAlertDialogValFromChild}
        />
      ) : null}

      {showUpdateSubscriptionDialog ? (
        <EditSubscriptionDialog
          selectedItem={selectedItem}
          updateSelectedItem={updateSelectedItemValFromChild}
          updateSubscriptionDialogEnable={showUpdateSubscriptionDialog}
          updateEditSubscriptionDialogState={updateEditSubscriptionDialogValFromChild}
        />
      ) : null}
    </Box>
  )
}
