import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    InputAdornment,
    Stack,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import utc from 'dayjs/plugin/utc'
import { ModifiedSubscriptionList } from './Model'
import { checkReturnValue } from 'components/Utility'
import { Result as Department } from 'reduxStore/subcription/Department/Model'
import { Result } from 'reduxStore/subcription/Active/Model'
import { Result as Status } from 'reduxStore/subcription/Status/Model'
import { numberWithoutDollar } from 'utils/numberWithCommas'
import { editSubscriptionApi, editSubscriptionClearRes, selectedSubscriptionEditDialogFromTable, updateSubscriptionEditDialogStateFromTable } from 'reduxStore/subcription/editSubscription/editSubscriptionSlice'
import { EditSubscriptionBody, MainBody } from 'reduxStore/subcription/editSubscription/editSubscriptionModel'
import { fetchSubscription } from 'reduxStore/subcription/Active/subscriptionSlice'

dayjs.extend(utc)

type Props = {
    selectedItem?: ModifiedSubscriptionList
    updateSelectedItem: (value: ModifiedSubscriptionList) => void
    updateSubscriptionDialogEnable?: boolean
    updateEditSubscriptionDialogState: (value: boolean) => void
    item?: Result | any
}

export default function EditSubscriptionDialog({
    selectedItem,
    updateSelectedItem,
    updateSubscriptionDialogEnable,
    updateEditSubscriptionDialogState,
}: Props) {
    const Token = localStorage.getItem('token')
    const dispatch = useAppDispatch()
    const [updateSubscriptionDialogOpen, setUpdateSubscriptionDialogOpen] = useState(false)
    useEffect(() => {
        setUpdateSubscriptionDialogOpen(updateSubscriptionDialogEnable!)
    }, [updateSubscriptionDialogEnable])


    //close Dialog
    const closeUpdatePaymentDialog = () => {
        setUpdateSubscriptionDialogOpen(false)
        updateEditSubscriptionDialogState(false)
        updateSelectedItem({} as ModifiedSubscriptionList)
        dispatch(updateSubscriptionEditDialogStateFromTable(false))
        dispatch(selectedSubscriptionEditDialogFromTable({} as any))
    }

    const handleClose = (_event: {}, reason: string) => {
        if (reason === "backdropClick") {
            return;
        }
        closeUpdatePaymentDialog();
    };

    const [subsName, setSubsName] = React.useState("")
    const [aliasName, setAliasName] = useState('')
    const [subsOwnerName, setSubsOwnerName] = useState('')
    const [subsDeptName, setSubsDeptName] = useState('')
    const [subStatusName, setSubStatusName] = useState('')
    const [subMonthlyprojectedCost, setSubMonthlyprojectedCost] = useState('')
    const [isActive, setIsActive] = useState(true);
    const handleSwitchChange = (event: any) => {
        setIsActive(event.target.checked);
    };
    const [dateValue, setDateValue] = React.useState<Dayjs | null>(null)
    const setNewDateValue = (newValue: Dayjs | null) => {
        setDateValue(dayjs(newValue))
    }
    useEffect(() => {
        setNotes(selectedItem?.description !== undefined ? selectedItem.description : '')
        setSubsName(selectedItem?.name !== undefined ? selectedItem.name : '')
        setSubsOwnerName(selectedItem?.userName !== undefined ? selectedItem.userName! : '')
        setSubsDeptName(selectedItem?.department !== undefined ? selectedItem.department! : '')
        setSubStatusName(selectedItem?.status !== undefined ? selectedItem.status! : '')
        setSubMonthlyprojectedCost(selectedItem?.monthly_projected_cost !== undefined ? selectedItem.monthly_projected_cost! : '')
        setAliasName(selectedItem?.aliasName !== '---' ? selectedItem?.aliasName! : '')
        if (selectedItem?.ignoreSubscription !== undefined) {
            setIsActive(!selectedItem.ignoreSubscription);
        }
        setDateValue(
            checkReturnValue(selectedItem?.renewalDate) !== 'Not Set'
                ? checkReturnValue(dayjs(selectedItem?.renewalDate))
                : null,
        )
    }, [selectedItem])
    const [selectedStatus, setSelectedStatus] = React.useState(null)
    const filterStatus = (status: any, _e: any) => {
        if (status !== null && status.id !== null) {
            setSelectedStatus(status.id)
        }
    }
    const subsValue = useAppSelector((state) => state.subscription)
    const subsStatusValue = useAppSelector((state) => state.subscriptionstatus)
    const companyUserList = useAppSelector((state) => state.companyUserList)
    const subsDepartmentValue = useAppSelector((state) => state.subsdepartment)
    const editSubscriptionValue = useAppSelector((state) => state.editSubscriptionfetch)
    const [statusValue, setStatusValue] = React.useState([] as Status[])
    const [monthlyCost, setMonthlyCost] = React.useState('')
    const [notes, setNotes] = useState('')
    const [departmentValue, setDepartmentValue] = React.useState([] as Department[])
    const [selectedDepartment, setSelectedDepartment] = React.useState(null)
    const filterDepartment = (department: any, _e: any) => {
        if (department !== null && department.id !== null) {
            setSelectedDepartment(department.id)
        }
    }
    useEffect(() => {
        if ('results' in subsValue.subscriptions) {
            if (subsValue.subscriptions.results.length > 0) {
            }
        }
        if ('status' in subsStatusValue) {
            if ('results' in subsStatusValue.status) {
                setStatusValue(subsStatusValue.status.results)
            }
        }
        if ('department' in subsDepartmentValue) {
            if ('results' in subsDepartmentValue.department) {
                setDepartmentValue(subsDepartmentValue.department.results)
            }
        }

        if ('companyUserList' in companyUserList) {
            if ('results' in companyUserList.companyUserList) {
                if (
                    companyUserList.companyUserList.results !== undefined ||
                    companyUserList.companyUserList.results !== null
                ) {
                    if (companyUserList.companyUserList.results.length > 0) {
                        for (var i = 0; i < companyUserList.companyUserList.results.length; i++) {
                            setOwnerValue(companyUserList.companyUserList.results)
                        }
                    }
                }
            }
        }

        if (editSubscriptionValue.editresponse.id !== undefined) {
            // setEnableBackdrop(true)
        } else {
            if (editSubscriptionValue.error === 'Something went wrong') {
                // setEnableBackdrop(true)
            } else {
                // setEnableBackdrop(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subsValue])
    const [ownerValue, setOwnerValue] = React.useState([] as any[])
    const [selectedOwner, setSelectedOwner] = React.useState(null)
    const filterOwner = (owner: any, _e: any) => {
        if (owner !== null && owner.id !== null) {
            setSelectedOwner(owner.id)
        }
    }
    const editSubscriptions = async (row: any) => {
        let editApiCallBody = {} as EditSubscriptionBody
        editApiCallBody = {
            description: notes,
            monthly_projected_cost:
                monthlyCost !== ''
                    ? monthlyCost.replace(',', '').replace('$', '')
                    : row.monthly_projected_cost.replace(',', '').replace('$', ''),
        } as EditSubscriptionBody
        if (dateValue !== null) {
            Object.assign(editApiCallBody, { renewal_date: dayjs(dateValue).format('YYYY-MM-DD') + 'T12:00' })
        }
        Object.assign(editApiCallBody, { ignore: !isActive })

        if (selectedOwner !== null) {
            Object.assign(editApiCallBody, { owner_id: selectedOwner })
        }
        if (selectedDepartment !== null) {
            Object.assign(editApiCallBody, { department_id: selectedDepartment })
        }
        if (selectedStatus !== null) {
            Object.assign(editApiCallBody, { status_id: selectedStatus })
        }
        if (aliasName !== null || aliasName !== '') {
            Object.assign(editApiCallBody, { vendor_alias: aliasName })
        }
        // Edit Subscription Main Body
        let mainBody = {
            id: row.accountId,
            token: Token!,
            body: editApiCallBody,
        } as MainBody
        await dispatch(editSubscriptionApi(mainBody))
        setUpdateSubscriptionDialogOpen(false)
        await dispatch(editSubscriptionClearRes())
        await dispatch(fetchSubscription(Token!))
        await setDateValue(null)
    }
    return (
        <Box>
            <Dialog open={updateSubscriptionDialogOpen} onClose={handleClose} disableEscapeKeyDown>
                <DialogTitle id="alert-dialog-title">
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Stack direction={'row'}>
                            <Typography variant="h6" fontWeight="bold">
                                {subsName}
                            </Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Tooltip title={isActive === true ? "Inactive subscriptions will be moved to the 'Ignored Subscriptions' list" : "Active subscriptions will no longer appear on the 'Ignored Subscriptions' list"}>
                                <FormControlLabel
                                    control={<Switch checked={isActive} onChange={handleSwitchChange} />}
                                    label={isActive === true
                                        ? "Active"
                                        : "Inactive"}
                                />
                            </Tooltip>
                        </Stack>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                        pb={2}
                    >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                id="subscriptionName-id"
                                label="Alias Name"
                                variant="standard"
                                value={aliasName}
                                onChange={(e) => {
                                    setAliasName(e.target.value)
                                }}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Renewal Date"
                                    value={dateValue}
                                    onChange={(value: any) => {
                                        setNewDateValue(value)
                                    }}
                                    slotProps={{ textField: { variant: 'standard' } }}
                                    sx={{ width: '100%' }}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                id="owner-states"
                                freeSolo
                                value={
                                    ownerValue.find(
                                        (element: any) =>
                                            element.id === selectedOwner ||
                                            element.user.username === subsOwnerName
                                    ) || { user: { username: subsOwnerName } }
                                }
                                options={ownerValue}
                                onChange={(event: any, newValue: any) => {
                                    if (newValue && newValue.user?.username) {
                                        filterOwner(newValue, event);
                                    } else if (typeof newValue === "string") {
                                        setSubsOwnerName(newValue);
                                    }
                                }}
                                getOptionLabel={(option: any) => option?.user?.username || ""}
                                isOptionEqualToValue={(option: any, value: any) =>
                                    option.id === value.id || option.user.username === value.user?.username
                                }
                                renderInput={(params) => (
                                    <TextField {...params} label="Owner" variant="standard" fullWidth />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                id="dept-states"
                                freeSolo
                                value={
                                    departmentValue?.find(
                                        (element: any) => element.id === selectedDepartment || element.name === subsDeptName
                                    ) || null
                                }
                                options={departmentValue}
                                onChange={(event: any, newValue: any) => {
                                    if (newValue && newValue.name) {
                                        filterDepartment(newValue, event);
                                    } else if (typeof newValue === 'string') {
                                        setSubsDeptName(newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Department Name" variant="standard" fullWidth />
                                )}
                                getOptionLabel={(option: any) => option.name}
                                isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                id="subscriptionMonthProCost-id"
                                label="Monthly Projected Cost"
                                variant="standard"
                                fullWidth
                                type="number"
                                onChange={(e) => {
                                    setMonthlyCost(e.target.value)
                                }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>
                                }}
                                defaultValue={
                                    subMonthlyprojectedCost !== undefined
                                        ? `${subMonthlyprojectedCost !== null
                                            ? numberWithoutDollar(Number(subMonthlyprojectedCost))
                                            : '0.00'
                                        }`
                                        : 'not-set'
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Autocomplete
                                id="status-states"
                                freeSolo
                                value={
                                    statusValue?.find(
                                        (element: any) => element.id === selectedStatus || element.name === subStatusName
                                    ) || null
                                }
                                options={statusValue}
                                onChange={(event: any, newValue: any) => {
                                    if (newValue && newValue.name) {
                                        filterStatus(newValue, event);
                                    } else if (typeof newValue === 'string') {
                                        setSubStatusName(newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Status" variant="standard" fullWidth />
                                )}
                                getOptionLabel={(option: any) => option.name}
                                isOptionEqualToValue={(option: any, value: any) => option.name === value.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextField
                                id="standardNote-id"
                                label="Notes"
                                variant="standard"
                                multiline
                                rows={3}
                                fullWidth
                                value={notes}
                                onChange={(e) => {
                                    setNotes(e.target.value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="text"
                        onClick={closeUpdatePaymentDialog}
                        disabled={editSubscriptionValue.loading}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item>
                                <Typography>Cancel</Typography>
                            </Grid>
                        </Grid>
                    </Button>
                    <Button variant="contained" onClick={() => editSubscriptions(selectedItem)}>
                        {editSubscriptionValue.loading === true ? (
                            <Stack direction="row" spacing={1}>
                                <Typography>Save</Typography>
                                <Box pl={1}>
                                    <CircularProgress color="inherit" size={18} />
                                </Box>
                            </Stack>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}
